
































import Vue from "vue";

export default Vue.extend({
  name: "Home",
  data(){
    return {
      store:"",
      loading:false,
      stores:[
       {name:'wave premium' , value: 0},
       {name:'wave' , value: 1},
       {name:'smile' , value: 2},
       {name:'aboali', value: 3}
      ],
    }
  },
  methods: {
    submit() {
      
      console.log(this.store)
    }
  },
});
